<template>
  <div class="FileUpload-view">
    <!--UPLOAD-->
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <input type="hidden" name="identifier" value="item" />
      <div class="dropbox">
        <input type="file" :name="uploadFieldName" :disabled="isSaving"
               @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
               accept=".csv,.json" class="input-file">
        <p v-if="isInitial">
          <slot>
            {{$t('uploadForm.dragHere')}}
          </slot>
        <p v-if="isSaving">
        {{$t('uploadForm.uploading')}} {{ fileCount }} {{$t('uploadForm.files')}}
        </p>
      </div>
    </form>
    <!--SUCCESS-->
    <div v-if="isSuccess">
      <p class="is-bold"><slot name="successMessage">Uploaded {{ uploadedFiles.length }} item(s) successfully.</slot></p>
      <p>
        <a href="javascript:void(0)" @click="reset()">Upload again</a>
      </p>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <p class="is-bold">Uploaded failed.</p>
      <p>
        <a href="javascript:void(0)" @click="reset()">Try again</a>
      </p>
    </div>
  </div>
</template>

<!-- Javascript -->
<script>
import { mapActions } from 'vuex'
const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3
export default {
  name: 'fileupload',
  props: ['filePath', 'item'],
  data () {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'file'
    }
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    },
    uploadTo () {
      return this.filePath
    }
  },
  methods: {
    ...mapActions({
      doUpload: 'devices/doUpload'
    }),
    reset () {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    async save (formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING
      try {
        const files = await this.doUpload({ data: formData, path: this.uploadTo })
        this.uploadedFiles = [].concat(files)
        this.currentStatus = STATUS_SUCCESS
        this.$emit('success', this.uploadedFiles)
        setTimeout(this.reset, 5000)
      } catch (err) {
        this.uploadError = err.response
        this.currentStatus = STATUS_FAILED
      }
    },
    filesChange (fieldName, fileList) {
      // handle file changes
      const formData = new FormData()
      if (!fileList.length) return
      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name)
        })

      // save it
      this.save(formData, this.uploadTo)
    }
  },
  mounted () {
    this.reset()
  }
}
</script>
<!-- SASS styling -->
<style scoped lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 25px; /* minimum height */
  position: relative;
  cursor: pointer;
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0
  }
}
.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}
.dropbox p {
  font-size: .8em;
  text-align: center;
  padding: 10px 0;
}
.dropbox pre {
  font-size:.5em;
}
</style>
