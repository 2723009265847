<template>
  <div>
    <DeviceReg />
  </div>
</template>

<script>
import DeviceReg from '@/components/devices/DeviceReg'
export default {
  components: {
    DeviceReg
  }
}
</script>
