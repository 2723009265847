<template>
  <b-card class="facility-card" >
    <h2 style="margin-bottom: 40px;">{{$t('devices.inventoryTitle')}}</h2>
    <Fileupload @success="uploadedFile" :file-path="uploadPath">
      {{$t('uploadForm.dragHere')}}
      <span slot="successMessage">{{$t('devices.listUpdateMsg')}}</span>
    </Fileupload>
    <div class="facility-card" >
      <v-sheet
        width="95%"
        height="95%"
        :elevation="4"
        color="rgba(120,120,120,0.5)"
        :tile="false"
        style="margin: auto auto 20px auto;"
      >
        <v-simple-table
          fixed-header height="100%"
          style=" margin: auto; text-align: center;"
        >
          <thead>
            <tr>
              <th scope="col" style="width: 42%; font-size: 22px; text-align: center;">Type</th>
              <th scope="col" style="width: 42%; font-size: 22px; text-align: center;">Device</th>
              <th scope="col" style="width: 16%; text-align: center; margin: auto; ">
              <v-btn
                 v-if="!newDevice && structureDevices"
                 color="primary"
                 elevation="2"
                 fab
                 icon
                 small
                 @click="makeNewForm()"
                 @keydown="makeNewForm()"
               >
                New
               </v-btn>
               </th>
            </tr>
          </thead>
          <tbody v-if="!newDevice && !structureDevices">
            <tr>
              <td colspan="3">
                <div style="width: 100%; cursor: pointer;">
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    @click="makeNewForm()"
                    @keydown="makeNewForm()"
                  >
                    Add New Device
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="newDevice">
            <tr>
              <td>
                <v-select
                  v-model="newDevice.deviceTypeId"
                  :items="['WALABOT_HOME', 'VAYYAR_HOME']"
                  autocomplete="off"
                  label="Device Type ID"
                  placeholder="Type Here"
                  small
                >
                </v-select>
              </td>
              <td>
                <v-text-field
                  v-model="newDevice.metadata.serialNumber"
                  autocomplete="off"
                  label="SKU"
                  placeholder="Type Here"
                  small
                >
                </v-text-field>
                 <v-text-field
                  v-model="newDevice.vendorDeviceId"
                  autocomplete="off"
                  label="Vendor Device ID"
                  placeholder="Type Here"
                  small
                >
                </v-text-field>
              </td>
              <td>
                <v-btn
                  color="warning"
                  elevation="2"
                  small
                  style="margin-bottom: 5px;"
                  @click="saveNewDevice()"
                  @keydown="saveNewDevice()"
                >
                  Create
                </v-btn>
                <br />
                <v-btn
                  color="primary"
                  elevation="2"
                  small
                  @click="makeNewForm()"
                  @keydown="makeNewForm()"
                >
                  Cancel
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-if="getDevices">
           <tr v-for="device in getDevices"  v-bind:key="device.id">
            <td>{{device.deviceTypeId}}</td>
             <td>{{device.metadata.serialNumber}}</td>
             <td>
               <v-btn
               color="primary"
               elevation="2"
               fab
               icon
               small
               :loading="loading.includes(device.id)"
               @click="trashDevice(device)"
               @keydown="trashDevice(device)"
             >
               <Icon
                 iconName="trash-fill"
                 size="15px"
                 color="grey"
                 :is-bootstrap="true"
               />
             </v-btn>
             </td>
           </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
    </div>
  </b-card>

</template>

<script>

import Fileupload from '@/components/fileUpload/FileUpload'
import { mapActions, mapGetters } from 'vuex'
import Icon from '@/components/elements/Icon'
export default {
  name: 'DeviceInventory',
  data () {
    return {
      loading: [],
      newDevice: null
    }
  },
  components: {
    Fileupload,
    Icon
  },
  computed: {
    ...mapGetters({
      getDevices: 'devices/getDevices',
      getCurrentCustomer: 'login/getCurrentCustomer',
      getRootContainer: 'containers/getRootContainer'
    }),
    uploadPath () {
      return '/new-devices/files'
    },
    structureDevices () {
      if (this.getDevices?.length) {
        return this.getDevices.map(device => ({
          Type: device.deviceTypeId,
          Device: device?.metadata?.serialNumber ?? this.$t('devices.noDeviceSerialNumber')
        })).sort((a, b) => {
          if (a.Name) return b.Name ? a.Name.localeCompare(b.Name) : 1
          else return b.Name ? -1 : 0
        })
      }
      return undefined
    }
  },
  methods: {
    ...mapActions({
      fetchDevices: 'devices/fetchDevices',
      deleteDevice: 'devices/deleteDevice',
      addDeviceToInventory: 'devices/addDeviceToInventory'
    }),
    makeNewForm () {
      if (this.newDevice) {
        this.newDevice = null
      } else {
        this.newDevice = {
          vendorDeviceId: '',
          deviceTypeId: '',
          metadata: {
            serialNumber: ''
          },
          name: ''
        }
      }
    },
    async saveNewDevice () {
      const newDevice = await this.addDeviceToInventory({ device: this.newDevice })
      console.log(newDevice)
      if (newDevice) {
        this.fetchDevices()
        this.newDevice = null
      }
    },
    async trashDevice (device) {
      this.loading.push(device.id)
      await this.deleteDevice(device)

      setTimeout(() => {
        this.loading = this.loading.filter(dev => dev !== device.id)
      }, 400)
      await this.fetchDevices()
    },
    async uploadedFile () {
      await this.fetchDevices()
    }
  },
  created () {
    this.fetchDevices()
  },
  watch: {
    async getCurrentCustomer (newVal) {
      if (newVal) {
        await this.fetchDevices()
      }
    }
  }
}
</script>
