<template>
  <div>
    <DeviceInventory />
  </div>
</template>

<script>
import DeviceInventory from '@/components/devices/DeviceInventory'
export default {
  components: {
    DeviceInventory
  }
}
</script>
