<template>
  <b-card class="facility-card" >
    <h2 style="margin-bottom: 40px;">{{$t('devices.registrationTitle')}}</h2>
    <div v-if="pccIntegration" class="facility-card">
      <b-button block variant="secondary" style="color: white" @click="onDownloadClick()" :disabled="!getContainers">
        {{$t('devices.downloadDeviceMsg')}}
      </b-button>
    </div>

    <FileUpload @success="uploadedFile" :file-path="uploadPath">
      {{$t('uploadForm.csvDeviceMsg')}}
      <span slot="successMessage">{{$t('facility.facilityUpdatedMsg')}}</span>
    </FileUpload>
    <div class="facility-card">
      <b-table bordered striped hover :items="items"   >
      </b-table>
    </div>
  </b-card>

</template>

<script>

import FileUpload from '@/components/fileUpload/FileUpload'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeviceReg',
  data () {
    return {
      items: []
    }
  },
  components: {
    FileUpload
  },
  computed: {
    ...mapGetters({
      loggedIn: 'login/isLoggedIn',
      myIntegrations: 'login/myIntegrations',
      getContainers: 'containers/getContainers',
      getRootContainer: 'containers/getRootContainer',
      getRegisteredDevices: 'devices/getRegisteredDevices'
    }),
    pccIntegration: function () {
      return this.myIntegrations?.type === 'AMPLIFY'
    },
    uploadPath () {
      return `/device-registrations/${this.getContainers?.id}/files`
    },
    structureDevices () {
      if (this.getRegisteredDevices?.length) {
        return this.getRegisteredDevices.map(item => {
          const location = item.container?.fullyQualifiedName.join('/') || item.container.name
          return {
            Type: item.device.deviceTypeId,
            'Device ID': item.device?.metadata?.serialNumber ?? this.$t('devices.noDeviceSerialNumber'),
            Location: location
          }
        }).sort((a, b) => {
          if (a.Name) return b.Name ? a.Name.localeCompare(b.Name) : 1
          else return b.Name ? -1 : 0
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      downloadContainerTree: 'containers/downloadContainerTree',
      fetchContainers: 'containers/fetchContainers',
      fetchDevices: 'devices/fetchRegisteredDevices',
      getRootContainer: 'containers/getRootContainer'
    }),
    async onDownloadClick () {
      const data = await this.downloadContainerTree(this.getContainers.id)
      const fileURL = window.URL.createObjectURL(
        new Blob([data], { type: 'text/csv' })
      )
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${this.getContainers.name}-device.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
      fileLink.remove()
    },
    async uploadedFile () {
      await this.fetchDevices()
    }
  },
  async created () {
    await this.fetchDevices()
  },
  watch: {
    async getCurrentCustomer (newVal) {
      if (newVal) {
        await this.fetchDevices()
        await this.fetchContainers()
      }
    },
    getRegisteredDevices (newVal, oldVal) {
      if (newVal) {
        this.items = this.structureDevices
      }
    },
    async getRootContainer (newVal, oldVal) {
      if (newVal && this.getCurrentCustomer) {
        await this.fetchDevices()
        await this.fetchContainers()
      }
    }
  }
}
</script>
